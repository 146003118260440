


import FETCHING from '../../library/fetching'
import UMUM from '../../library/umum'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form : {
        id : '',
        tgl_kolektif_kolegial : '',
        tgl_buat_paket : '',
        type_kegiatan : '',
        type_tender : '',
        tahun_anggaran : '',
        mtd_pemilihan : '',
        nama_klpd : '',
        kontrak_pembayaran : '',
        kualifikasi_paket : '',
        kd_satker : '',
        kd_satker_str : '',
        kd_rup : '',
        kd_pkt_dce : '',
        kd_klpd : '',
        kd_lpse : '',
        jenis_klpd : '',
        ket_diulang : '',
        ket_ditutup : '',
        jenis_pengadaan : '',
        lokasi_pekerjaan : '',
        nama_lpse : '',
        nama_paket : '',
        pagu : '',
        sumber_dana : '',
        url_lpse : '',
        hps : '',


        kd_tender : '',
        mtd_evaluasi : '',
        mtd_kualifikasi : '',
        nama_pokja : '',
        nama_ppk : '',
        nama_satker : '',
        nip_pokja : '',
        nip_ppk : '',
        status_tender : '',
        tanggal_status : '',
        tgl_pengumuman_tender : '',
        versi_tender : '',
        versi_nontender : '',
        tgl_pengumuman_nontender : '',
        nip_nama_ppk : '',
        nip_nama_pp : '',
        nip_nama_pokja : '',
        mak : '',
        kd_nontender : '',
        status_nontender : '',
        nama_satker : '',

        versi_nontender : '',
        tgl_pengumuman_nontender : '',
        nip_nama_ppk : '',
        nip_nama_pp : '',
        nip_nama_pokja : '',
        mak : '',
        kd_nontender : '',
        status_nontender : '',
        nama_satker : '',

        nama_subkegiatan : '',
        kd_subkegiatan_str : '',
        nama_kegiatan : '',
        kd_kegiatan_str : '',
        nama_program : '',
        kd_program_str : '',


        

      },

      filterku : {
        kd_satker : '',
        tahun_anggaran  : '',
        type_kegiatan : '',
        status_umumkan_rup : '',
        mtd_pemilihan : '',
        jenis_pengadaan : '',
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
    
      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : true,


      mdl_detile: false,
      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,


      FETCHING : FETCHING,
      UMUM : UMUM,
      DATA_MASTER : DATA_MASTER,


      penyediaAllow : true
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_CT_PAKET_PENYEDIA + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit,


              kd_satker : this.filterku.kd_satker,
              tahun_anggaran  : this.filterku.tahun_anggaran,
              type_kegiatan : this.filterku.type_kegiatan,
              status_umumkan_rup : this.filterku.status_umumkan_rup,

              mtd_pemilihan : this.filterku.mtd_pemilihan,
              jenis_pengadaan : this.filterku.jenis_pengadaan,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_CT_PAKET_PENYEDIA + "Add", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_CT_PAKET_PENYEDIA + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_CT_PAKET_PENYEDIA + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){

        this.form.id = data.id ;
        this.form.tgl_kolektif_kolegial = data.tgl_kolektif_kolegial ;
        this.form.tgl_buat_paket = data.tgl_buat_paket ;
        this.form.type_kegiatan = data.type_kegiatan ;
        this.form.type_tender = data.type_tender ;
        this.form.tahun_anggaran = data.tahun_anggaran ;
        this.form.mtd_pemilihan = data.mtd_pemilihan ;
        this.form.nama_klpd = data.nama_klpd ;
        this.form.kontrak_pembayaran = data.kontrak_pembayaran ;
        this.form.kualifikasi_paket = data.kualifikasi_paket ;
        this.form.kd_satker = data.kd_satker ;
        this.form.kd_satker_str = data.kd_satker_str ;
        this.form.kd_rup = data.kd_rup ;
        this.form.kd_pkt_dce = data.kd_pkt_dce ;
        this.form.kd_klpd = data.kd_klpd ;
        this.form.kd_lpse = data.kd_lpse ;
        this.form.jenis_klpd = data.jenis_klpd ;
        this.form.ket_diulang = data.ket_diulang ;
        this.form.ket_ditutup = data.ket_ditutup ;
        this.form.jenis_pengadaan = data.jenis_pengadaan ;
        this.form.lokasi_pekerjaan = data.lokasi_pekerjaan ;
        this.form.nama_lpse = data.nama_lpse ;
        this.form.nama_paket = data.nama_paket ;
        this.form.pagu = data.pagu ;
        this.form.sumber_dana = data.sumber_dana ;
        this.form.url_lpse = data.url_lpse ;
        this.form.hps = data.hps ;

        this.form.kd_tender = data.kd_tender ;
        this.form.mtd_evaluasi = data.mtd_evaluasi ;
        this.form.mtd_kualifikasi = data.mtd_kualifikasi ;
        this.form.nama_pokja = data.nama_pokja ;
        this.form.nama_ppk = data.nama_ppk ;
        this.form.nama_satker = data.nama_satker ;
        this.form.nip_pokja = data.nip_pokja ;
        this.form.nip_ppk = data.nip_ppk ;
        this.form.status_tender = data.status_tender ;
        this.form.tanggal_status = data.tanggal_status ;
        this.form.tgl_pengumuman_tender = data.tgl_pengumuman_tender ;
        this.form.versi_tender = data.versi_tender ;
        this.form.versi_nontender = data.versi_nontender ;
        this.form.tgl_pengumuman_nontender = data.tgl_pengumuman_nontender ;
        this.form.nip_nama_ppk = data.nip_nama_ppk ;
        this.form.nip_nama_pp = data.nip_nama_pp ;
        this.form.nip_nama_pokja = data.nip_nama_pokja ;
        this.form.mak = data.mak ;
        this.form.kd_nontender = data.kd_nontender ;
        this.form.status_nontender = data.status_nontender ;
        this.form.nama_satker = data.nama_satker ;

        this.form.versi_nontender = data.versi_nontender ;
        this.form.tgl_pengumuman_nontender = data.tgl_pengumuman_nontender ;
        this.form.nip_nama_ppk = data.nip_nama_ppk ;
        this.form.nip_nama_pp = data.nip_nama_pp ;
        this.form.nip_nama_pokja = data.nip_nama_pokja ;
        this.form.mak = data.mak ;
        this.form.kd_nontender = data.kd_nontender ;
        this.form.status_nontender = data.status_nontender ;
        this.form.nama_satker = data.nama_satker ;

        this.form.nama_subkegiatan = data.nama_subkegiatan ;
        this.form.kd_subkegiatan_str = data.kd_subkegiatan_str ;
        this.form.nama_kegiatan = data.nama_kegiatan ;
        this.form.kd_kegiatan_str = data.kd_kegiatan_str ;
        this.form.nama_program = data.nama_program ;
        this.form.kd_program_str = data.kd_program_str ;

    },

    syncKode : function() {
      fetch(this.$store.state.url.URL_CT_PAKET_PENYEDIA + "syncData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun_anggaran: this.filterku.tahun_anggaran,
          })
      }).then(res_data => {
          this.Notify('Sukses Sinkronisasi Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    autocomplete_unit_kerja : function (val, update) {
        update(() => {
          if (val === '') {}
          else {DATA_MASTER.getUnitKerjaAuto2(val, this.filterku.kd_satker)}
        })
    },
    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

        resetMetodeJenis(){
          this.filterku.jenis_pengadaan = "";
          this.filterku.mtd_pemilihan = "";


          if (this.filterku.type_kegiatan == 0) {
            this.penyediaAllow = true
          } else {
            this.penyediaAllow = false
          }

          // console.log("PENYEDIA: "+this.filterku.jenis_pengadaan)
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    // FETCHING.getContohAtocomplete('')

    const d = new Date();
    let year = d.getFullYear();

    this.filterku.tahun_anggaran = year


    var profilex = JSON.parse(localStorage.profile).profile
    this.filterku.kd_satker = profilex.kd_satker
    DATA_MASTER.getUnitKerjaAuto2('', this.filterku.kd_satker)

    this.getView();
  },
}
